module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"qibri","description":"The first digital assistant for all your company's (best-) practices","short_name":"qibri. Transform everyday.","start_url":"/","background_color":"#fff","theme_color":"#593da6","display":"standalone","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ff7e410c3a9054910165d44662d67978"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
